.draggable-main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: 'Raleway', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}

.content {
  position: relative;
  width: 320px;
  height: 240px;
  
}

.content > div {
  position: absolute;
  width: 320px;
  height: 90px;
  overflow: visible;
  font-weight: bold;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 90px;
  padding-left: 32px;
  font-size: 14.5px;
  background: lightblue;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
}

.content > div:nth-child(1) {
  background: linear-gradient(135deg, #242424 0%, #242424 100%);
}
.content > div:nth-child(2) {
  background: linear-gradient(135deg, #242424 0%, #242424 100%);
}
.content > div:nth-child(3) {
  background: linear-gradient(135deg, #242424 0%, #242424 100%);
}
.content > div:nth-child(4) {
  background: linear-gradient(135deg, #242424 0%, #242424 100%);
}
.content > div:nth-child(5) {
  background: linear-gradient(135deg, #242424 0%, #242424 100%);
}
.content > div:nth-child(6) {
  background: linear-gradient(135deg, #242424 0%, #242424 100%);
}